<script lang="ts" setup>
import { onMounted, ref } from 'vue';

const props = defineProps({
  speed: {
    type: Number,
    default: 50
  },
  reverse: {
    type: Boolean,
    default: false
  },
});

const contentNumber = ref(1);
const elements = ref<HTMLDivElement[]>([]);
const elementNumber = ref(1);

onMounted(() => {
  contentNumber.value = 2;
  elementNumber.value = elements.value?.[0]?.children?.length ?? 1;
});
</script>

<template>
  <div class="marquee">
    <div class="marquee__body transform-gpu" :class="{ 'marquee--reverse': props.reverse, 'marquee--start': contentNumber > 1 }" :style="{ '--marquee-speed': `${props.speed * elementNumber}s` }">
      <div class="marquee__content" v-for="n in contentNumber" :key="n" :aria-hidden="n > 1" ref="elements">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.marquee {
  display: flex;
  overflow: hidden;
  white-space: nowrap;

  .marquee__body {
    position: relative;

    .marquee__content {
      display: flex;
      animation-duration: var(--marquee-speed);
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      will-change: transform;

      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
      }
    }

    &.marquee--start {
      .marquee__content {
        &:nth-child(1) {
          animation-name: marquee-first;
        }

        &:nth-child(2) {
          display: flex;
          animation-name: marquee-second;
        }
      }
    }

    /* Reverse animation */
    &.marquee--reverse {
      &.marquee--start {
        .marquee__content {
          &:nth-child(1) {
            animation-name: marquee-first-reverse;
          }

          &:nth-child(2) {
            display: flex;
            animation-name: marquee-second-reverse;
          }
        }
      }
    }

    /* Pause on hover */
    &:hover {
      .marquee__content {
        animation-play-state: paused;
      }
    }

    @keyframes marquee-first {
      from {
        transform: translateX(0%);
      }

      to {
        transform: translateX(-100%);
      }
    }

    @keyframes marquee-second {
      from {
        transform: translateX(100%);
      }

      to {
        transform: translateX(0%);
      }
    }

    @keyframes marquee-first-reverse {
      from {
        transform: translateX(0%);
      }

      to {
        transform: translateX(100%);
      }
    }

    @keyframes marquee-second-reverse {
      from {
        transform: translateX(-100%);
      }

      to {
        transform: translateX(0%);
      }
    }
  }
}
</style>
